import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import React from 'react'
import { Link } from 'react-router-dom'

const styles = theme => getStyles({
	logo: {
    marginLeft: 0,
    marginRight: 10,
	}
})

const Logo = ({ classes }) =>
	(
		<Link to='/' className={classes.logo}>
			<img alt="logo" src='/img/logo-carglass.jpg' width={100}/>
		</Link>
	)

export default withStyles(styles)(Logo)
