import { Grid, IconButton, Input, Typography, withStyles } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { SpringDataTable, dataTableActions } from 'isotope-client'
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styles from '../../styles'
import { injectMobiles } from './services/mobilesInjector'

const Monitoring = ({ classes, deleteMobile, refreshTable }) => {
	const mobilesDatatable = React.createRef()
	const [searchImeiValue, setSearchImeiValue] = React.useState(null)

	return (
		<Grid
			container
			justify="center"
			alignItems="center"
			className={classes.accueilPaper}
		>
			<Grid item>
				<Typography variant="h6">Gestion des mobiles</Typography>
				<Input id="search-imei"
				       value={searchImeiValue}
				       onChange={event => {
					       const { value } = event.target
					       setSearchImeiValue(value)
				       }}
				       placeholder="Recherche par IMEI" />
				<SpringDataTable
					apiUrl="/mobile"
					nom="mobilesDatatable"
					ref={mobilesDatatable}
					filters={{ imei: searchImeiValue }}
					headers={[
						{
							key: 'imei',
							name: 'IMEI',
							type: 'string',
							sortable: true
						},
						{
							key: 'creationDate',
							name: 'Date d\'enregistrement',
							sortable: true
						},
						{
							key: 'lastConnexion',
							name: 'Dernière connexion',
							sortable: true
						},
						{
							key: 'suppression',
							name: '',
							render: row => (
								<IconButton color="primary" component="span" onClick={() =>
									deleteMobile(row.imei)
										.then(refreshTable)
								}>
									<Delete />
								</IconButton>
							)
						}
					]}
				/>
			</Grid>
		</Grid>
	)
}

export default compose(
	connect(undefined,{
		refreshTable: () => dataTableActions.refresh('mobilesDatatable')
	}),
	withStyles(styles),
	injectMobiles
)(Monitoring)
