import { Grid, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import styles from '../../styles'

const AccueilPage = ({ classes }) => (
	<Grid
		container
		justify="center"
		alignItems="center"
		className={classes.accueilPaper}
	>
		<Grid item>
			<Typography variant="h6">Bienvenue sur le backoffice de votre application</Typography>
		</Grid>
	</Grid>
)

export default withStyles(styles)(AccueilPage)