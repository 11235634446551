import { MuiThemeProvider } from '@material-ui/core'
import createHistory from 'history/createBrowserHistory'
import { createIsotopeTheme, fetchUser as fetchUserApi, messages as messagesIsotope } from 'isotope-client'
import React from 'react'
import ReactDOM from 'react-dom'
import { intlReducer, Provider } from 'react-intl-redux'
import { ConnectedRouter, routerMiddleware, connectRouter } from 'connected-react-router'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { reducer as formReducer } from 'redux-form'
import thunk from 'redux-thunk'
import messages from './config/messages'
import reducers from './config/reducers'
import Root from './containers/Root'
import './index.css'

const theme = createIsotopeTheme({
  login: {
    background: 'url(\'img/connexion.png\')'
  },
  typography: {
    subheading: {
      fontSize: 15
    }
  },
  palette: {
    primary: {
      main: '#fe2107',
      contrastText: '#FFFFFF'
    }
  },
  overrides: {
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: 'transparent'
        }
      },
      selected: {}
    },
    MuiMenuItem: {
      selected: {
        color: '#fe2107'
      }
    }
  }
})

// Cette méthode permet d'enfermer tout le code à exécuter après les vérifications sur le login
const reactInit = (user) => {

  // Initialisation de la partie i18n (react-intl)
  const formats = {
    date: {
      datetime: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }
    }
  }

  // Create a history of your choosing (we're using a browser history in this case)
  const history = createHistory()

  // Initialisation de tous les enhancers utilisés avec Redux
  const enhancers = [
    applyMiddleware(
      thunk,
      routerMiddleware(history)
    )
  ]

  // En mode dév, on utilise le devtools
  if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
  }

	// Création du store de redux
	let store = createStore(
		combineReducers({
			...reducers,
			router: connectRouter(history),
			intl: intlReducer,
			form: formReducer
		}), {
			user,
			intl: {
				locale: 'fr',
				messages: {
					...messages,
					...messagesIsotope
				},
				formats: {
					...formats
				},
				defaultLocale: 'fr',
				defaultFormats: {
					...formats
				}
			}
		},
		compose(...enhancers)
	)

  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={theme}>
          <Root />
        </MuiThemeProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  )
}

const token = window.localStorage.getItem('token')
if (token) {
  fetchUserApi(token).then(user => {
    if (user) {
      user.token = token
      user.authenticated = true
    }
    reactInit(user)
  }).catch((error) => {
    console.log(error)
    reactInit()
  })
} else {
  reactInit()
}

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./containers/Root', () => {
    reactInit()
  })
}
