import React, {Component} from 'react'
import ExtractionForm from './components/ExtractionForm'

class ExtractionPage extends Component {
	render() {
		return (
			<ExtractionForm/>
		)
	}
}

export default (ExtractionPage)
