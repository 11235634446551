import { GET_CODES_LIST_SUCCESS } from './extractionActions'

const local = {
  codesList: []
}

const extraction = (state = local, actions) => {
  switch (actions.type) {
    case GET_CODES_LIST_SUCCESS :
      return {
        ...state,
        codesList: actions.codesList
      }
    default:
      return state
  }
}

export default extraction
