import {
	LoginPage,
	IsotopeContext
} from 'isotope-client'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import CarglassLogo from '../img/CarglassLogo'
import App from './App'

export default () => (
	<IsotopeContext.Provider
		value={{
			appName: 'TDR',
			logo: CarglassLogo
		}}
	>

		<Switch>
			<Route path='/login' component={LoginPage} />
			<Route path='/logout' component={LoginPage} />
			<Route path='/' component={App} />
			<Route path='*' component={App} />
		</Switch>
	</IsotopeContext.Provider>
)
