import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
	FormInput,
	Title,
	FormSelect,
	getStyles
} from 'isotope-client'
import { Button, withStyles, MenuItem } from '@material-ui/core'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import * as extractionActions from '../services/extractionActions'

const styles = () => getStyles({
	margin: {
		marginTop: 20,
		marginLeft: 60,
		marginBottom: 100,
	},
	errorText: {
		color: '#f44336',
		fontSize: 10
	}
})

const validate = (values) => {
	const errors = {}

	if (!values.startDate) {
		errors.startDate = <FormattedMessage id='validation.notEmpty' />
	}

	if (!values.endDate) {
		errors.endDate = <FormattedMessage id='validation.notEmpty' />
	}

	if (values.startDate && values.endDate && values.endDate <= values.startDate) {
		errors.endDate = <FormattedMessage id='extractForm.endDateError' />
	}

	if (!values.entityType) {
		errors.entityType = <FormattedMessage id='validation.notEmpty' />
	}

	return errors
}

const date = new Date()
const TODAY = date.toISOString().slice(0, 10)
date.setDate(date.getDate() + 1)
const TOMORROW = date.toISOString().slice(0, 10)

class ExtractionForm extends Component {

	componentDidMount() {
		const { getCodesList, initialValues } = this.props

		getCodesList(initialValues.entityType)
	}

	submitExract = (data) => {
		const { extract } = this.props

		extract(data)
	}

	getCodesList = (event, value) => {
		const { getCodesList } = this.props

		getCodesList(value)
	}

	render() {
		const { handleSubmit, classes, codesList } = this.props
		const emptyCodesList = !codesList || codesList.length === 0

		return (
			<React.Fragment>
				<div
					className={classes.margin}
				>
					<form
						onSubmit={handleSubmit(this.submitExract)}
					>
						<Title
							value={<FormattedMessage id='extractForm.periodTitle' />}
						/>
						<Field
							name="startDate"
							id="startDate"
							type="date"
							label={<FormattedMessage id='extractForm.startDate' />}
							component={FormInput}
						/>
						<Field
							name="endDate"
							id="endDate"
							type="date"
							label={<FormattedMessage id='extractForm.endDate' />}
							component={FormInput}
						/>
						<Title
							value={<FormattedMessage id='extractForm.entityTitle' />}
						/>
						<Field
							name="entityType"
							id="entityType"
							label={<FormattedMessage id='extractForm.entityType' />}
							component={FormSelect}
							onChange={this.getCodesList}
						>
							<MenuItem
								key="TICKET"
								value="TICKET"
							>
								Tickets MPOWER
							</MenuItem>
							<MenuItem
								key="INVOICE"
								value="INVOICE"
							>
								Factures REMEDY
							</MenuItem>
						</Field>
							{emptyCodesList &&
								<div className={classes.errorText}>
									<FormattedMessage id='extractForm.originNotFound' />
								</div>
							}

						<Field
							name="originCode"
							id="originCode"
							label={<FormattedMessage id='extractForm.originCode' />}
							component={FormSelect}
							disabled={emptyCodesList}
						>
							<MenuItem
								key="all"
								value="all"
							>
								Tous
							</MenuItem>
							{codesList.map(code => (
								<MenuItem
									key={code}
									value={code}
								>
									{code}
								</MenuItem>
							))}
						</Field>

						<Button
							type="submit"
							color="primary"
							variant="contained"
							disabled={emptyCodesList}
						>
							<FormattedMessage id='extractForm.btnExtract' />
						</Button>
					</form>
				</div>
			</React.Fragment>
		)
	}
}

ExtractionForm.propTypes = {
	codesList: PropTypes.array.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	getCodesList: PropTypes.func.isRequired,
	extract: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
	initialValues: {
		startDate: TODAY,
		endDate: TOMORROW,
		entityType: 'TICKET',
		originCode: 'all'
	},
	codesList: state.extraction.codesList
})

const actions = {
	getCodesList: extractionActions.getCodesList,
	extract: extractionActions.extract
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'extractionForm',
		enableReinitialize: true,
		validate
	}),
	withStyles(styles, { name: 'extractionpage' })
)(ExtractionForm)
