import { Grid, IconButton, Typography, withStyles } from '@material-ui/core'
import { Replay } from '@material-ui/icons'
import { dataTableActions, SpringDataTable } from 'isotope-client'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import styles from '../../styles'
import { injectArchivedMobiles } from './services/archivedInjector'

const ArchivedMobiles = ({ classes, reactivateMobile, refreshTable }) => {
	const archivedMobilesDatatable = React.createRef()

	return (
		<Grid
			container
			justify="center"
			alignItems="center"
			className={classes.accueilPaper}
		>
			<Grid item>
				<Typography variant="h6">Gestion des mobiles bloqués</Typography>
				<SpringDataTable
					apiUrl="/archived"
					nom="archivedMobilesDatatable"
					ref={archivedMobilesDatatable}
					headers={[
						{
							key: 'imei',
							name: 'IMEI',
							type: 'string',
							sortable: true
						},
						{
							key: 'lastArchive',
							name: 'Date de dernière archive',
							sortable: true
						},
						{
							key: 'reactivate',
							name: '',
							render: row => (
								<IconButton color="primary" component="span" onClick={() =>
									reactivateMobile(row.imei, row.publicKey)
										.then(refreshTable)
								}>
									<Replay />
								</IconButton>
							)
						}
					]}
				/>
			</Grid>
		</Grid>
	)
}

export default compose(
	connect(undefined, {
		refreshTable: () => dataTableActions.refresh('archivedMobilesDatatable')
	}),
	withStyles(styles),
	injectArchivedMobiles
)(ArchivedMobiles)
